<template>
  <v-app>
    <v-container
      fluid
      class="fill-height pa-0 bg-color no-gutters"
    >
      <v-snackbar
        v-model="unauthorizedError"
        light
        color="ccred lighten-1"
      >
        {{ unauthorizedError }}
      </v-snackbar>
      <v-dialog
        v-model="notSuperAdmin"
        persistent
        width="500"
      >
        <v-card>
          <v-card-text>
            <p>
              You don't have required privileges to access admin tool. Please contact support if you think you should have access.
            </p>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <base-app-dialog-action-button
              @click="notSuperAdmin = false"
            >
              OK
            </base-app-dialog-action-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row
        align="center"
        class="no-gutters fill-height pa-0"
      >
        <!-- top -->
        <v-col
          cols="2"
          offset="5"
          class="text-center"
        >
          <v-img
            class="mx-auto"
            height="100%"
            :src="logo"
          />
        </v-col>
        <!-- middle -->
        <v-col
          cols="12"
          align-self="start"
        >
          <v-row
            class="pa-0 no-gutters"
            align="end"
          >
            <v-col>
              <p
                v-if="error"
                class="text-center red--text"
              >
                {{ error }}
              </p>
            </v-col>
          </v-row>
          <v-row
            class="pa-0 no-gutters"
          >
            <v-col>
              <v-card
                class="mx-auto bg-circles"
                height="400"
                width="640"
              >
                <transition
                  name="component-fade"
                  mode="out-in"
                >
                  <!--
                <transition-group
                  :name="back ? 'slideback' : 'slide'"
                >
                -->
                  <v-card-text
                    v-if="!forgotPassword"
                    key="login"
                  >
                    <v-row class="pa-0 ma-0 no-gutters">
                      <v-col cols="12">
                        <p class="text-center text-h3">
                          LOG IN
                        </p>
                      </v-col>
                      <v-col
                        cols="8"
                        offset="2"
                      >
                        <v-text-field
                          v-model="email"
                          placeholder="Email"
                          filled
                        />
                      </v-col>
                      <v-col
                        cols="8"
                        offset="2"
                      >
                        <v-text-field
                          v-model="password"
                          placeholder="Password"
                          filled
                          type="password"
                        />
                      </v-col>
                      <v-col cols="12">
                        <p class="text-center">
                          <v-btn
                            class="mx-auto"
                            color="primaryTeal"
                            :disabled="!email || !password"
                            @click="logIn"
                          >
                            Submit
                          </v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-text
                    v-else
                    key="forgot"
                  >
                    <v-row class="pa-0 ma-0 no-gutters">
                      <v-col cols="12">
                        <p class="text-center text-h3">
                          PASSWORD RECOVERY
                        </p>
                      </v-col>
                      <v-col cols="12">
                        <p class="text-center">
                          Enter the email you're using for your account.
                        </p>
                      </v-col>
                      <v-col
                        cols="8"
                        offset="2"
                      >
                        <v-text-field
                          v-model="email"
                          placeholder="Email"
                          filled
                        />
                      </v-col>
                      <v-col cols="12">
                        <p class="text-center">
                          <v-btn
                            class="mx-auto"
                            color="primaryTeal"
                            @click="recoverPassword"
                          >
                            Continue
                          </v-btn>
                        </p>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </transition>
                <v-card-actions />
              </v-card>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>
              <p
                v-if="!forgotPassword"
                class="text-center text-caption text-link"
                @click="forgotPassword = !forgotPassword"
              >
                forgot your password?
              </p>
              <p
                v-else
                class="text-center text-caption text-link"
                @click="forgotPassword = !forgotPassword"
              >
                Back to Log in
              </p>
            </v-col>
          </v-row>
          <v-row class="no-gutters">
            <v-col>
              <p class="text-center text-caption text-link">
                I don't have a password
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
  import { CCConstants } from '@/constants'
  import { eventBus, busEvents } from '@/EventBus'
  import { mapGetters, mapMutations } from 'vuex'

  export default {
    name: 'LoginIndex',

    components: {
    },

    data: () => ({
      back: false,
      forgotPassword: false,
      expandOnHover: false,
      logo: require('@/assets/logo_login.svg'),
      footer: require('@/assets/login_footer.svg'),
      circles: require('@/assets/circlesLogin.svg'),
      email: null,
      password: null,
      error: null,
      notSuperAdmin: false,
      allowedRoles: [
        CCConstants.SUPER_ADMIN,
        CCConstants.CUSTOMER_SUPPORT,
      ],
    }),

    computed: {
      ...mapGetters({
        unauthorized: 'app/UNAUTHORIZED',
      }),
      unauthorizedError: {
        get () {
          return this.unauthorized
        },
        set () {
          this.setUnauthorizedError(null)
        },
      },
    },

    methods: {
      ...mapMutations({
        setUnauthorizedError: 'app/SET_UNAUTHORIZED_ERROR',
        setUser: 'app/SET_USER',
      }),
      async logIn () {
        const { email, password } = this
        this.$careConnect.logIn(email, password)
          .then(
            results => {
              if (results.roles.some(r => this.allowedRoles.includes(r))) {
                this.setUser(results)
                this.$router.push({ name: 'Agencies' })
              } else if (results.roles && !results.roles.some(r => this.allowedRoles.includes(r))) {
                eventBus.$emit(busEvents.LOGIN_NOT_SUPERADMIN)
                this.notSuperAdmin = true
              }
            },
            error => {
              this.error = error.json.error
            },
          )
      },
      recoverPassword () {
        const { email } = this
        this.$careConnect.recover(email)
          .then(
            results => {
              this.forgotPassword = !this.forgotPassword
            },
            error => {
              this.error = error.json.error
            },
          )
      },
    },
  }
</script>

<style scoped>

.bg-color {
  background-color: rgb(247, 247, 247);
  background-image: url('~@/assets/login_footer.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;
  background-position: bottom;
}
.bg-circles {
  background-image: url('~@/assets/circlesLogin.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-attachment: scroll;
  background-position: bottom;
  overflow: hidden;
}

.text-link {
  cursor: pointer;
}

.component-fade-enter-active, .component-fade-leave-active {
  transition: all .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  transform: translateX(100%);
}

.slide-leave-active,
.slide-enter-active {
  transition: .1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(-100%, 0);
}

.slideback-leave-active,
.slideback-enter-active {
  transition: 1s;
}
.slideback-enter {
  transform: translate(-100%, 0);
}
.slideback-leave-to {
  transform: translate(100%, 0);
}
</style>
